<template>
  <v-dialog v-model="value" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">{{ title }} </v-card-title>
      <v-card-text> {{ description }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Cancel" color="error" outlined small @click="onCancel" />
        <ac-button title="OK" small @click="onOK" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AcConfirmationDialog',
  components: {
    AcButton: () => import('@/components/AcButton'),
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Confirm',
    },
    description: {
      type: String,
      default: 'Description',
    },
  },

  methods: {
    onCancel() {
      this.$emit('onCancel')
    },
    onOK() {
      this.$emit('onOK')
    },
  },
}
</script>

<style lang="scss" scoped></style>
