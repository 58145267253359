import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c(VCardActions,[_c(VSpacer),_c('ac-button',{attrs:{"title":"Cancel","color":"error","outlined":"","small":""},on:{"click":_vm.onCancel}}),_c('ac-button',{attrs:{"title":"OK","small":""},on:{"click":_vm.onOK}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }